<template>
    <div>
        <Header title="Grupos" addText="Grupo" :hasAddBtn="rules.includes('create.group.api')" :addFields="addFields" :meta="meta"/>
        <b-table sticky-header="65vh" no-border-collapse hover :items="groups" :fields="fields">
            <template #cell(actions)="row">
                <div class="btn-icons">
                    <i v-if="rules.includes('update.group.api')" @click="$emit('callModal', 'form-modal', 'edit', row.item, addFields, meta)" class="material-icons btn-icon">edit</i>
                    <i @click="$router.push({ name: 'UsersFromGroups', params: { group: row.item.id }})" class="material-icons btn-icon">person</i>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>

import Header from '@/components/Dashboard/Header'

import { createNamespacedHelpers } from 'vuex'

const mapState = createNamespacedHelpers('groups').mapState

export default {
  components: {
    Header
  },
  data: () => ({
    meta: {
      title: 'Grupo',
      module: 'groups',
      state: 'groups',
      modes: {
        add: {
          title: 'Criar novo Grupo',
          success: 'Grupo Criado com successo',
          urn: 'group/create',
          response: 'group',
          method: 'post'
        },
        edit: {
          title: 'Editar Grupo',
          success: 'Grupo Editado com successo',
          urn: 'group/update',
          response: 'group',
          method: 'put'
        }
      }
    },
    fields: [
      { key: 'key', label: 'Chave', sortable: true },
      { key: 'type', label: 'Tipo', sortable: true },
      { key: 'description', label: 'Descrição', sortable: true },
      {
        key: 'public',
        label: 'Público',
        formatter: (value, key, item) => { return value ? 'Sim' : 'Não' },
        sortable: true
      },
      { key: 'actions', label: 'Ações', sortable: true }
    ],
    addFields: [
      { key: 'key', label: 'Chave', type: 'text' },
      { key: 'type', label: 'Tipo', type: 'text' },
      { key: 'description', label: 'Descrição', type: 'text' },
      { key: 'master_group_id', label: 'Grupo Pai', type: 'select', options: { value: 'id', text: 'key' } },
      { key: 'group_id', type: 'hidden', value: { self: 'id' } }
    ]
  }),
  computed: {
    ...mapState(['groups']),
    rules () {
      return this.$store.state.rules.map(a => a.key)
    }
  }
}
</script>
